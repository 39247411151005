<template>
  <FocusBar :forceSave="true" :save="saveLeadRotation">
    <template #middle>
      <div class="justify-center items-center hidden md:flex">
        <div class="flex justify-center items-center w-fit gap-3 mx-4">
          <StringField
            class="text-2xl"
            placeholder="Enter form name"
            v-model="lead_rotation_name"
          />
          <font-awesome-icon icon="pencil" class="text-cool-gray-800 ml-4" />
        </div>
      </div>
    </template>
    <template #changes>
      <Btn v-if="lead_rotation_id" @click="viewPreview" severity="tertiary">
        <template #icon>
          <font-awesome-icon :icon="['far', 'eye']" />
        </template>
        <span class="hidden md:block">Web preview</span>
      </Btn>
    </template>
  </FocusBar>
</template>

<script setup>
import { computed } from 'vue'
import RouteEntityContext from '../composables/RouteEntityContext'
import EntityComputedFields from '../composables/EntityFields/EntityComputedFields'
import useForm from '@/components/forms/Form'
import FocusBar from '../layout/header/FocusBar.vue'
import Saving from '@/components/headers/Saving'
import StringField from '@/components/ui/Calculator/StringField.vue'

const { refId, type, storeName, changes, isDirty } = RouteEntityContext.useRouteEntityContext({
  trackChanges: true
})

const { saveForm } = useForm()

const { save } = Saving.useSaving({
  refId,
  changes,
  isDirty,
  type,
  storeName
})

const { lead_rotation_name, lead_rotation_id, form_id } =
  EntityComputedFields.useEntityComputedFields({
    refId,
    type,
    store: storeName
  })

const publicFormLink = computed(() => {
  const baseUrl = `${import.meta.env.VITE_FE_PROTOCOL}${import.meta.env.VITE_FE_BASE}`
  return `${baseUrl}/leadForm/${lead_rotation_id.value}`
})

const viewPreview = () => {
  window.open(publicFormLink.value, '_blank')
}

const saveLeadRotation = async () => {
  try {
    // save form
    const form = await saveForm()
    // pass form id to lead rotation
    form_id.value = form.form_id
    // save lead rotation
    await save()
  } catch (e) {
    console.log(e, 'ERROR COULD NOT SAVE OPEN QUOTE')
  }
}
</script>
